<template>
  <div>
    <div class="card" v-if="!loading">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <a
              href="#"
              class="float-right float-end"
              @click.prevent="partners.push({})"
              ><i class="lni lni-plus mr-2"></i>Add Partner</a
            >
            <h4>Our Partners</h4>
          </div>
          <div
            class="col-md-3"
            v-for="(partner, i) in partners"
            :key="`partner-${i}`"
          >
            <div class="form-group">
              <label>Type</label>
              <select v-model="partner.type" class="form-control">
                <option value="image">Image</option>
                <option value="text">Text</option>
              </select>
            </div>
            <div class="form-group" v-if="partner.type == 'text'">
              <label>Title</label>
              <input
                type="text"
                v-model="partner.content"
                placeholder="Link"
                class="form-control"
              />
              <input type="file" ref="partner_logo" style="display: none">
            </div>
            <div class="form-group" v-if="partner.type == 'image'">
              <img
                :src="partner.content"
                :alt="partner.content"
                style="height: 80px"
              />
              <label
                >Logo
                <small class="text-muted"
                  >Leave blank to not change</small
                ></label
              >
              <input type="file" ref="partner_logo" class="form-control" />
            </div>
            <div class="form-group">
              <label>Link</label>
              <input
                type="text"
                v-model="partner.link"
                placeholder="Link"
                class="form-control"
              />
            </div>
            <div class="form-group text-center">
              <a href="#" @click.prevent="partners.splice(i, 1)"
                ><i class="lni lni-trash-can mr-2"></i>Delete</a
              >
            </div>
          </div>
          <div class="col-md-12">
            <hr />
            <a
              href="#"
              class="btn btn-success text-white"
              @click.prevent="update"
              ><i class="lni lni-save mr-2"></i>Save</a
            >
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      partners: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/pages/partners").then((response) => {
        this.partners = response.data.content.partners;
        this.loading = false;
      });
    },

    update() {
      let data = new FormData
      data.append("type", "partners");
      
      this.partners.forEach((partner, i) => {
        data.append(`partners[${i}][type]`, partner.type)
        data.append(`partners[${i}][link]`, partner.link)
        data.append(`partners[${i}][content]`, partner.content)

        if(partner.type == 'image') {
          if(this.$refs.partner_logo[i].files[0]) {
            data.append(`partners[${i}][new_logo]`, this.$refs.partner_logo[i].files[0])
          }
        }
      })

      this.loading = true
      this.$axios.post('/api/v1/admin/pages/partners', data).then(() => {
        this.fetch()
      })
    }
  },
};
</script>
